// ** React Imports
import { useState, useMemo } from 'react';

// ** Next Imports
import Link from 'next/link';

// ** Mui Imports
import { Box, Menu, Avatar, Typography, Divider, Button, IconButton, Stack, useTheme, Badge, Tooltip, Skeleton } from '@mui/material';
import { IconCheck, IconCopy } from '@tabler/icons-react';

// ** Hooks Imports
import { useAccount } from 'wagmi';
import { useHandleNetwork } from '@/context/network';
import { useSession } from '@/context/session';
import { useLens } from '@/context/lens';

// ** Components
import { SignInBtn } from '@/components/ctaButtons/wallet/SignInWallet';
import { SwitchNetwork } from '@/components/ctaButtons/network/SwitchNetwork';

// ** Utils Imports
import { profile as dropdownData } from './data';
import { truncateAddress } from '@/utils';
import { getSignInStatus } from '@/utils/helpers';
import { SignInStatus } from '@/types/custom';
const availableDropdowns = dropdownData.filter(data => !data.hide);

/////////////////////////
const Profile = () => {
  const theme = useTheme();
  const {
    isConnected
  } = useAccount();
  const {
    unsupportedNetwork
  } = useHandleNetwork();
  const {
    user
  } = useSession();
  const {
    logOut,
    isLoadingSignIn
  } = useSession();
  const {
    profileId
  } = useLens();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isAddressCopied, setIsAddressCopied] = useState(false);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const profilePic = useMemo(() => user.picture ?? '', [user]);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleCopyAddress = () => {
    navigator.clipboard.writeText(user.address as string);
    setIsAddressCopied(true);
    setTimeout(() => {
      setIsAddressCopied(false);
    }, 1000);
  };

  /*************************************************
   *                   Render                      *
   *************************************************/

  const signInStatus = getSignInStatus();
  const isSignPending = signInStatus === SignInStatus.pending;
  const isSignRejected = signInStatus === SignInStatus.rejected;
  const isBadgeHidden = !isSignPending;
  const completionRequired = isSignPending || isSignRejected;
  return <Box data-sentry-element="Box" data-sentry-component="Profile" data-sentry-source-file="Profile.tsx">
      <IconButton size="large" color="inherit" aria-controls="msgs-menu" aria-haspopup="true" sx={{
      ...(typeof anchorEl2 === 'object' && {
        color: 'primary.main'
      })
    }} onClick={handleClick2} data-sentry-element="IconButton" data-sentry-source-file="Profile.tsx">
        {isLoadingSignIn ? <Skeleton variant="circular" width={45} height={45} /> : <Avatar src={profilePic} alt={'ProfileImg'} sx={{
        width: 45,
        height: 45
      }} />}
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu id="msgs-menu" anchorEl={anchorEl2} keepMounted open={Boolean(anchorEl2)} onClose={handleClose2} anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom'
    }} transformOrigin={{
      horizontal: 'right',
      vertical: 'top'
    }} sx={{
      '& .MuiMenu-paper': {
        width: {
          xs: '80%',
          sm: '360px'
        },
        minWidth: {
          xs: '200px',
          sm: undefined
        },
        maxWidth: {
          xs: '360px',
          sm: undefined
        },
        p: 4
      }
    }} data-sentry-element="Menu" data-sentry-source-file="Profile.tsx">
        {profileId && <Stack>
            <Typography variant="h5">User Profile</Typography>
            <Stack direction="row" py={3} spacing={2} alignItems="center">
              <Badge invisible={isBadgeHidden} badgeContent={'!'} color="error" overlap="circular" anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
                <Link href={`/profile/${profileId}`}>
                  <Avatar src={profilePic} alt={'ProfileImg'} sx={{
                width: {
                  xs: 75,
                  sm: 95
                },
                height: {
                  xs: 75,
                  sm: 95
                }
              }} />
                </Link>
              </Badge>
              <Stack direction="column" spacing={0.3}>
                <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
                  {user.handle ? user.handle : truncateAddress(user.address as `0x${string}`)}
                </Typography>
                {user && <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Typography variant="body1" color="textSecondary">
                      {truncateAddress(user.address as `0x${string}`)}
                    </Typography>
                    <Tooltip title="Copy Address" placement="top" arrow>
                      {isAddressCopied ? <IconCheck width={15} height={15} color={theme.palette.secondary.dark} /> : <IconCopy aria-describedby="copy-address" width={15} height={15} color={theme.palette.secondary.dark} onClick={handleCopyAddress} style={{
                  cursor: 'pointer'
                }} />}
                    </Tooltip>
                  </Stack>}
              </Stack>
            </Stack>
          </Stack>}
        {isConnected && completionRequired && <>
            {!unsupportedNetwork ? <>
                <Divider />
                <Stack direction="row" py={3} spacing={2} alignItems="center">
                  <Typography variant="subtitle2" color="error" textAlign={'center'}>
                    You need to complete the login.
                  </Typography>
                  <SignInBtn label="Complete" color="error" size="small" variant={undefined} />
                </Stack>
              </> : <>
                <Divider />
                <Stack direction="row" py={3} spacing={2} alignItems="center">
                  <Typography variant="subtitle2" color="error" textAlign={'center'}>
                    You are in an unsupported network
                  </Typography>
                  <SwitchNetwork size="small" color="error" />
                </Stack>
              </>}
          </>}
        <Divider data-sentry-element="Divider" data-sentry-source-file="Profile.tsx" />
        {profileId && availableDropdowns.map(profile => <Box key={profile.title}>
              <Box sx={{
          py: {
            xs: 1,
            sm: 2
          },
          px: 0
        }} className="hover-text-primary">
                <Link href={profile.href}>
                  <Stack direction="row" spacing={2}>
                    <Box width="45px" height="45px" bgcolor="primary.light" display="flex" alignItems="center" justifyContent="center" flexShrink="0">
                      <Avatar src={profile.icon} alt={profile.icon} sx={{
                  width: 24,
                  height: 24,
                  borderRadius: 0
                }} />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" fontWeight={600} color="textPrimary" className="text-hover" noWrap>
                        {profile.title}
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" noWrap>
                        {profile.subtitle}
                      </Typography>
                    </Box>
                  </Stack>
                </Link>
              </Box>
            </Box>)}
        <Box mt={2} data-sentry-element="Box" data-sentry-source-file="Profile.tsx">
          <Button variant="outlined" color="primary" onClick={logOut} fullWidth data-sentry-element="Button" data-sentry-source-file="Profile.tsx">
            Logout
          </Button>
        </Box>
      </Menu>
    </Box>;
};
export default Profile;