import { SxProps, useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Stack, Box, useMediaQuery, Theme, Avatar } from '@mui/material';
import { useSelector } from '../../../src/store/Store';
import { AppState } from '../../../src/store/Store';
type Props = {
  title?: string;
  titleSize?: number;
  titleImg?: string;
  titleEmoji?: string;
  subtitle?: string;
  titleSubAction?: JSX.Element | any;
  action?: JSX.Element | any;
  footer?: JSX.Element;
  cardheading?: string | JSX.Element;
  headtitle?: string | JSX.Element;
  headsubtitle?: string | JSX.Element;
  children?: JSX.Element;
  middlecontent?: string | JSX.Element;
  sxContent?: SxProps;
  contentPadding?: string;
  sxMainCard?: SxProps;
};
const DashboardCard = ({
  title,
  titleSize,
  titleImg,
  titleEmoji,
  subtitle,
  children,
  titleSubAction,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
  sxContent,
  contentPadding,
  sxMainCard
}: Props) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return <Card sx={{
    padding: 0,
    border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
    ...sxMainCard
  }} elevation={customizer.isCardShadow ? 9 : 0} variant={!customizer.isCardShadow ? 'outlined' : undefined} data-sentry-element="Card" data-sentry-component="DashboardCard" data-sentry-source-file="DashboardCard.tsx">
      {cardheading ? <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent> : <CardContent sx={{
      padding: contentPadding ? contentPadding : '30px',
      ...sxContent
    }}>
          {title ? <Stack direction={smDown ? 'column' : 'row'} spacing={2} justifyContent="space-between" alignItems={'center'} mb={contentPadding ? 2 : 3}>
              <Box>
                {titleSubAction ? <Stack direction={'column'} spacing={1}>
                    <Box>
                      {title ? <Typography variant="h5" textAlign={smDown ? 'center' : 'left'}>
                          {title}
                        </Typography> : ''}

                      {subtitle ? <Typography variant="subtitle2" color="textSecondary" textAlign={smDown ? 'center' : 'left'}>
                          {subtitle}
                        </Typography> : ''}
                    </Box>

                    {titleSubAction}
                  </Stack> : <>
                    {title ? <Box display="flex" alignItems="center" padding={contentPadding ? contentPadding : '0px'}>
                        {titleImg && <Avatar src={titleImg} alt="Icon" style={{
                marginRight: 8
              }} />}
                        <Typography variant="h5" fontSize={titleSize} textAlign={smDown ? 'center' : 'left'}>
                          {title} {titleEmoji}
                        </Typography>
                      </Box> : ''}

                    {subtitle ? <Typography variant="subtitle2" color="textSecondary" textAlign={smDown ? 'center' : 'left'}>
                        {subtitle}
                      </Typography> : ''}
                  </>}
              </Box>
              {action}
            </Stack> : null}

          {children}
        </CardContent>}

      {middlecontent}
      {footer}
    </Card>;
};
export default DashboardCard;