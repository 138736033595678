// ** Mui, Next & React
import Link from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { Avatar, Box, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { IconThumbUp } from '@tabler/icons-react';

// ** Components
import InfiniteScroll from 'react-infinite-scroll-component';
import BlankCard from '@/components/shared/BlankCard';
import ProfileHover from '@/components/shared/ProfileHover';
import { PeopleFilter, Protocol } from '../PeopleComponent';

// ** Contexts
import { useSession } from '@/context/session';
import { useAccount } from 'wagmi';

// ** Utils
import { getAvatarUrl, getRankingEmoji } from '@/utils';
import { ProfileItem, TrendingUserType } from '@/types/custom';
import { ProfileFragment } from '@lens-protocol/client';
type TrendingUser = TrendingUserType | {
  user: ProfileFragment;
  ratioCount: number;
  topFollowers: ProfileItem[];
  rank?: number;
};
type Props = {
  users: TrendingUser[];
  hasMoreUsers: boolean;
  handlePageChange: () => Promise<void>;
  ratio: PeopleFilter;
  adjusted: string;
  compareUser: (userIdToCompare: string) => Promise<void>;
  isDetailView: boolean;
  protocol: Protocol;
};
export const MostLikedTableDesktop = ({
  users,
  hasMoreUsers,
  handlePageChange,
  ratio,
  adjusted,
  compareUser,
  isDetailView,
  protocol
}: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userIdToCompare, setUserIdToCompare] = useState<string | null>(null);
  const theme = useTheme();
  const router = useRouter();
  const {
    user: loggedUser
  } = useSession();
  const {
    isConnected
  } = useAccount();
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>, userId: string) => {
    if (!loggedUser.profileId || !isConnected) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setUserIdToCompare(userId);
  };
  const handleCompareUser = async (userId: string) => {
    await compareUser(userId);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setUserIdToCompare(null);
  };
  return <BlankCard data-sentry-element="BlankCard" data-sentry-component="MostLikedTableDesktop" data-sentry-source-file="desktop.tsx">
      <Box id={`scrollContainer-${ratio}`} sx={{
      maxHeight: '550px',
      overflow: 'auto'
    }} data-sentry-element="Box" data-sentry-source-file="desktop.tsx">
        <TableContainer sx={{
        '& .MuiTableCell-root': {
          padding: '8px 4px'
        }
      }} data-sentry-element="TableContainer" data-sentry-source-file="desktop.tsx">
          <Table aria-label="people table" size="small" data-sentry-element="Table" data-sentry-source-file="desktop.tsx">
            <TableHead data-sentry-element="TableHead" data-sentry-source-file="desktop.tsx">
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="desktop.tsx">
                <Typography textAlign="center" variant="subtitle2" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="desktop.tsx">
                  Rank
                </Typography>
              </TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="desktop.tsx">
                <Typography textAlign="center" variant="subtitle2" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="desktop.tsx">
                  User
                </Typography>
              </TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="desktop.tsx">
                <Stack direction="row" alignItems="center" justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="desktop.tsx">
                  <IconThumbUp size={16} color={theme.palette.primary.main} data-sentry-element="IconThumbUp" data-sentry-source-file="desktop.tsx" />
                  <Typography variant="caption" color={theme.palette.primary.main} data-sentry-element="Typography" data-sentry-source-file="desktop.tsx">
                    7d.
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="desktop.tsx">
                <Typography textAlign="center" variant="subtitle2" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="desktop.tsx">
                  Followed by
                </Typography>
              </TableCell>
            </TableHead>
            <TableBody data-sentry-element="TableBody" data-sentry-source-file="desktop.tsx">
              {users.map((item, index) => {
              const {
                user
              } = item;
              const ranking = 'rank' in item ? item.rank : index + 1;
              return <TableRow key={`${user.id}-${ranking}`} sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                  transition: 'background-color 0.3s'
                },
                height: '48px'
              }}>
                    <TableCell sx={{
                  maxWidth: '2em'
                }}>
                      <Stack direction="row" spacing={0.2} alignItems="center">
                        <Typography variant="subtitle1" color="textSecondary">
                          {`#${ranking === -1 ? 'N/A' : ranking?.toLocaleString()}`}
                        </Typography>
                        {ranking <= 3 && <Typography variant="subtitle1">
                            {getRankingEmoji(ranking)}
                          </Typography>}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{
                  maxWidth: '9vw'
                }}>
                      <Box sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      '& .avatar': {
                        filter: 'brightness(0.5)',
                        transition: '0.2s'
                      },
                      '& .name': {
                        color: theme.palette.primary.main,
                        transition: '0.2s'
                      },
                      '& .handle': {
                        color: theme.palette.grey[400],
                        transition: '0.2s'
                      }
                    }
                  }}>
                        <ProfileHover key={user.id} user={'txHash' in user ? user : undefined} formattedUser={!('txHash' in user) ? user : undefined} protocol={protocol}>
                          <Link href={protocol === Protocol.LENS ? `/profile/${user.id}` : `/fid/${user.id}`} style={{
                        textDecoration: 'none',
                        color: 'inherit'
                      }}>
                            <Stack key={user.id} direction="row" alignItems="center" spacing={1} sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            '& .avatar': {
                              filter: 'brightness(0.5)',
                              transition: '0.2s'
                            },
                            '& .name': {
                              color: theme.palette.primary.main,
                              transition: '0.2s'
                            },
                            '& .handle': {
                              color: theme.palette.grey[400],
                              transition: '0.2s'
                            }
                          }
                        }}>
                              <Avatar className="avatar" src={getAvatarUrl(user)} sx={{
                            width: 32,
                            height: 32
                          }} />
                              <Box sx={{
                            width: '60%'
                          }}>
                                <Typography variant="body2" className="name" noWrap sx={{
                              maxWidth: {
                                xs: '20px',
                                sm: '50px',
                                md: '100px'
                              },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block'
                            }}>
                                  {user.metadata?.displayName}
                                </Typography>
                                <Typography variant="caption" className="handle" noWrap fontSize={12} lineHeight={1.3} sx={{
                              maxWidth: {
                                xs: '20px',
                                sm: '50px',
                                md: '100px'
                              },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block'
                            }}>
                                  {user.handle && 'suggestedFormatted' in user.handle ? `@${user.handle?.suggestedFormatted.localName}` : `@${user.handle?.localName}`}
                                </Typography>
                              </Box>
                            </Stack>
                          </Link>
                        </ProfileHover>
                      </Box>
                    </TableCell>
                    <TableCell scope="row" align="center" style={{
                  backgroundColor: '#f0f0f0'
                }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {item.ratioCount === -1 ? 'N/A' : Number(item.ratioCount).toLocaleString()}
                      </Typography>
                    </TableCell>
                    <TableCell scope="row" align="center">
                      <Box display="flex" justifyContent="center">
                        <Stack direction="row" spacing={-0.5}>
                          {item.topFollowers.map((follower, i) => <Tooltip title={follower.handle?.localName} key={i}>
                              <Avatar src={getAvatarUrl(follower)} alt={follower.handle?.localName} sx={{
                          width: 24,
                          height: 24,
                          border: `2px solid ${theme.palette.background.paper}`
                        }} />
                            </Tooltip>)}
                        </Stack>
                      </Box>
                    </TableCell>
                  </TableRow>;
            })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </BlankCard>;
};