// ** React, Mui & Next
import { useEffect, useState } from 'react';
import { Box, CircularProgress, useTheme, useMediaQuery, ButtonGroup, Grid, Chip, Button } from '@mui/material';
import { IconX } from '@tabler/icons-react';

// ** Components
import PageContainer from '../container/PageContainer';
import DashboardCard from '../shared/DashboardCard';
import Search from './Search';
import CategoryFilter from './CategoryFilter';
import { MostFollowedTableDesktop } from './mostFollowed/desktop';
import { MostFollowedTableMobile } from './mostFollowed/mobile';
import { MostLikedTableDesktop } from './mostLiked/desktop';
import { MostLikedTableMobile } from './mostLiked/mobile';
import { MostCollectedTableDesktop } from './mostCollected/desktop';
import { MostCollectedTableMobile } from './mostCollected/mobile';

// ** Hooks & utils
import { useBackend } from '../../context/backend';
import { ProfileFragment } from '@lens-protocol/client';
import { ProfileItem, CategoryType, PeopleTableType } from '@/types/custom';
import { SharePeople } from '../ui-components/share/SharePeople';
import { useLens } from '@/context/lens';
import { useSession } from '@/context/session';
import { isDevelopMode } from '@/utils';
import { DevOnly } from '../devOnly';
import { useCategoryFilter } from '@/context/categoryFilter';
export const enum PeopleFilter {
  NONE = 'None',
  FOLLOWERS = 'Followers',
  LIKES = 'Likes',
  COLLECTS = 'Collects',
}
export const enum Protocol {
  LENS = 'Lens',
  FARCASTER = 'Farcaster',
}
const adjusted = 'Global';
type SelectedUser = {
  user: ProfileFragment | ProfileItem;
  ratioCount: number;
  topFollowers: ProfileItem[];
  rank?: number;
};

/////////////////////////////////////////////
export default function PeopleComponent() {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    fetchUsersTrendings,
    fetchUserTrendingRank,
    fetchCategoriesList,
    getUserFarcaster
  } = useBackend();
  const {
    getProfileByProfileId
  } = useLens();
  const {
    user
  } = useSession();
  const {
    selectedPeopleCategories,
    refreshNeeded
  } = useCategoryFilter();
  const [currentRatio, setCurrentRatio] = useState<PeopleFilter>(PeopleFilter.FOLLOWERS);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUser[]>([]);
  const [selectedProtocol, setSelectedProtocol] = useState<Protocol>(Protocol.LENS);
  const [isSearching, setIsSearching] = useState<Boolean>(false);
  const [isComparing, setIsComparing] = useState<Boolean>(false);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [tableData, setTableData] = useState<PeopleTableType>({
    followers: {
      users: [],
      pagination: {
        page: 1,
        total: 0,
        totalPages: 1
      }
    },
    likes: {
      users: [],
      pagination: {
        page: 1,
        total: 0,
        totalPages: 1
      }
    },
    collects: {
      users: [],
      pagination: {
        page: 1,
        total: 0,
        totalPages: 1
      }
    }
  });
  const [originalTableData, setOriginalTableData] = useState<PeopleTableType | null>(null);
  useEffect(() => {
    initialUsersLoad();
    fetchCategories();
    return cleanState;
  }, [currentRatio, selectedProtocol, refreshNeeded]);
  const initialUsersLoad = async () => {
    try {
      setIsLoading(true);
      await Promise.all([loadTableData(PeopleFilter.FOLLOWERS), loadTableData(PeopleFilter.LIKES), loadTableData(PeopleFilter.COLLECTS)]);
    } catch (error) {
      console.log('Error fetching initial users', error);
    } finally {
      setIsLoading(false);
    }
  };
  const loadTableData = async (filterType: PeopleFilter, page: number = 1) => {
    try {
      const selectedCategoriesIds = selectedPeopleCategories.map(category => category.id);
      const data = await fetchUsersTrendings(filterType, adjusted, page, 10, currentRatio === PeopleFilter.COLLECTS ? Protocol.LENS : selectedProtocol, selectedCategoriesIds);
      if (!data) return;
      const key = filterType.toLowerCase() as keyof typeof tableData;
      const sortedUsers = data.users.sort((a, b) => b.ratioCount - a.ratioCount);
      setTableData(prev => ({
        ...prev,
        [key]: {
          users: page === 1 ? sortedUsers : [...prev[key].users, ...sortedUsers],
          pagination: {
            page: data.page,
            total: data.total,
            totalPages: data.totalPages
          }
        }
      }));
    } catch (error) {
      console.log(`Error fetching ${filterType} data`, error);
    }
  };
  const fetchCategories = async () => {
    const response = await fetchCategoriesList();
    setCategories(response);
  };
  const cleanState = () => {
    setSelectedUser([]);
    setOriginalTableData(null);
  };
  const handlePageChange = async (filterType: PeopleFilter) => {
    const key = filterType.toLowerCase() as keyof typeof tableData;
    const currentPage = tableData[key].pagination.page;
    const totalPages = tableData[key].pagination.totalPages;
    if (currentPage < totalPages) {
      await loadTableData(filterType, currentPage + 1);
    }
  };
  const fetchUserTrendingData = async (userId: string, ratioFilter: PeopleFilter, user?: ProfileFragment | ProfileItem) => {
    try {
      if (!user) {
        const userData = selectedProtocol === Protocol.LENS ? await getProfileByProfileId(userId) : await getUserFarcaster(userId);
        if (!userData) throw new Error('No user data found');
        user = userData;
      }
      const ratioType = ratioFilter === PeopleFilter.LIKES ? 'likes' : ratioFilter === PeopleFilter.FOLLOWERS ? 'follows' : 'collects';
      const rankData = await fetchUserTrendingRank(userId, ratioType, selectedProtocol);
      const userRatio = rankData && rankData.count ? Number(rankData.count) : -1;
      const userRank = rankData && rankData.rank ? Number(rankData.rank) : -1;
      const userTopFollowers = rankData ? rankData.topFollowers : [];
      const userWithDetails = {
        user,
        ratioCount: userRatio,
        topFollowers: userTopFollowers,
        rank: userRank
      };
      return userWithDetails;
    } catch (error) {
      console.log('Error adding user to table', error);
    }
  };
  const addUserToTable = async (user: ProfileFragment | ProfileItem) => {
    try {
      setIsSearching(true);
      const [followersData, likesData, collectsData] = await Promise.all([fetchUserTrendingData(user.id, PeopleFilter.FOLLOWERS, user), fetchUserTrendingData(user.id, PeopleFilter.LIKES, user), fetchUserTrendingData(user.id, PeopleFilter.COLLECTS, user)]);
      if (!followersData || !likesData || !collectsData) {
        throw new Error('No user data found');
      }
      setOriginalTableData(tableData);
      setTableData({
        followers: {
          users: [{
            profileId: user.id,
            handle: user.handle,
            ratioCount: followersData.ratioCount,
            rank: followersData.rank || -1,
            ratio: 'follows',
            interest: [],
            user: user,
            topFollowers: followersData.topFollowers
          }],
          pagination: {
            page: 1,
            total: 1,
            totalPages: 1
          }
        },
        likes: {
          users: [{
            profileId: user.id,
            handle: user.handle,
            ratioCount: likesData.ratioCount,
            rank: likesData.rank || -1,
            ratio: 'likes',
            interest: [],
            user: user,
            topFollowers: likesData.topFollowers
          }],
          pagination: {
            page: 1,
            total: 1,
            totalPages: 1
          }
        },
        collects: {
          users: [{
            profileId: user.id,
            handle: user.handle,
            ratioCount: collectsData.ratioCount,
            rank: collectsData.rank || -1,
            ratio: 'collects',
            interest: [],
            user: user,
            topFollowers: collectsData.topFollowers
          }],
          pagination: {
            page: 1,
            total: 1,
            totalPages: 1
          }
        }
      });
      setSelectedUser([followersData]);
    } catch (error) {
      console.log('Error adding user to table', error);
    } finally {
      setIsSearching(false);
    }
  };
  const handleCompareUsers = async (userIdToCompare: string) => {
    if (!user.profileId) return;
    try {
      setIsComparing(true);
      const [currentUser, comparedUser] = await Promise.all([fetchUserTrendingData(user.profileId, currentRatio), fetchUserTrendingData(userIdToCompare, currentRatio)]);
      if (!currentUser || !comparedUser) {
        throw new Error('No user data found');
      }
      const selecteds = [currentUser, comparedUser];

      // Sort based on rank or ratioCount
      const sortedUsers = selecteds.sort((a, b) => {
        if (a.rank === -1 && b.rank === -1) {
          return a.ratioCount - b.ratioCount;
        }
        return (a.rank === -1 ? Infinity : a.rank) - (b.rank === -1 ? Infinity : b.rank);
      });
      setSelectedUser(sortedUsers);
    } catch (error) {
      console.log('Error comparing users', error);
    } finally {
      setIsComparing(false);
    }
  };
  const handleClearTable = () => {
    if (originalTableData) {
      setTableData(originalTableData);
      setOriginalTableData(null);
    }
    setSelectedUser([]);
  };
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="PeopleComponent" data-sentry-source-file="PeopleComponent.tsx">
      <DashboardCard title="People trends" subtitle="(Last 7 days)" contentPadding="0px" sxContent={{
      paddingX: isXsScreen ? 0 : '2em'
    }} action={<Box display="flex" justifyContent={isXsScreen ? 'space-between' : 'flex-end'} gap={2} alignItems="center" width="70%">
            <DevOnly>
              <ButtonGroup variant="contained" size="small" aria-label="Social network">
                <Button variant={selectedProtocol === Protocol.LENS ? 'contained' : 'outlined'} onClick={() => setSelectedProtocol(Protocol.LENS)}>
                  Lens
                </Button>
                <Button variant={selectedProtocol === Protocol.FARCASTER ? 'contained' : 'outlined'} onClick={() => setSelectedProtocol(Protocol.FARCASTER)}>
                  Farcaster
                </Button>
              </ButtonGroup>
            </DevOnly>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Search addUser={addUserToTable} protocol={selectedProtocol} />
              {!isSearching && selectedUser.length ? <Chip label="clear" onClick={handleClearTable} icon={<IconX size={18} />} variant="outlined" color="warning" sx={{
          cursor: 'pointer',
          transition: 'background-color 0.3s, color 0.3s',
          '&:hover': {
            color: theme.palette.grey[400]
          }
        }} /> : null}
              {isSearching || isComparing ? <Chip label={isComparing ? 'Comparing...' : 'Searching...'} onClick={handleClearTable} icon={<CircularProgress size={18} />} variant="outlined" color="info" sx={{
          cursor: 'pointer',
          transition: 'background-color 0.3s, color 0.3s',
          '&:hover': {
            color: theme.palette.grey[400]
          }
        }} /> : null}
            </Box>
          </Box>} data-sentry-element="DashboardCard" data-sentry-source-file="PeopleComponent.tsx">
        <>
          <Box display="flex" justifyContent="center" maxWidth="100%" mb={2} flexGrow={1} data-sentry-element="Box" data-sentry-source-file="PeopleComponent.tsx">
            <CategoryFilter categories={categories} data-sentry-element="CategoryFilter" data-sentry-source-file="PeopleComponent.tsx" />
          </Box>

          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '50vh'
        }}>
              <CircularProgress />
            </Box> : <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                {isXsScreen ? <DashboardCard title="Most followed" contentPadding="0px" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most followed" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.followers.users} />}>
                    <MostFollowedTableMobile users={tableData.followers.users} hasMoreUsers={tableData.followers.pagination.page < tableData.followers.pagination.totalPages} handlePageChange={() => handlePageChange(PeopleFilter.FOLLOWERS)} protocol={selectedProtocol} ratio={PeopleFilter.FOLLOWERS} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                  </DashboardCard> : <DashboardCard title="Most followed" contentPadding="0.2em" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most followed" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.followers.users} />}>
                    <MostFollowedTableDesktop users={tableData.followers.users} hasMoreUsers={tableData.followers.pagination.page < tableData.followers.pagination.totalPages} handlePageChange={() => handlePageChange(PeopleFilter.FOLLOWERS)} protocol={selectedProtocol} ratio={PeopleFilter.FOLLOWERS} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                  </DashboardCard>}
              </Grid>

              <Grid item xs={12} lg={6}>
                {isXsScreen ? <DashboardCard title="Most liked" contentPadding="0px" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most liked" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.likes.users} />}>
                    <MostLikedTableMobile users={tableData.likes.users} hasMoreUsers={tableData.likes.pagination.page < tableData.likes.pagination.totalPages} protocol={selectedProtocol} handlePageChange={() => handlePageChange(PeopleFilter.LIKES)} ratio={PeopleFilter.LIKES} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                  </DashboardCard> : <DashboardCard title="Most liked" contentPadding="0.2em" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most liked" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.likes.users} />}>
                    <MostLikedTableDesktop users={tableData.likes.users} hasMoreUsers={tableData.likes.pagination.page < tableData.likes.pagination.totalPages} protocol={selectedProtocol} handlePageChange={() => handlePageChange(PeopleFilter.LIKES)} ratio={PeopleFilter.LIKES} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                  </DashboardCard>}
              </Grid>

              {selectedProtocol !== Protocol.FARCASTER && (isXsScreen ? <Grid item xs={12} lg={6}>
                    <DashboardCard title="Most collected" contentPadding="0px" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most collected" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.collects.users} />}>
                      <MostCollectedTableMobile users={tableData.collects.users} hasMoreUsers={tableData.collects.pagination.page < tableData.collects.pagination.totalPages} handlePageChange={() => handlePageChange(PeopleFilter.COLLECTS)} protocol={selectedProtocol} ratio={PeopleFilter.COLLECTS} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                    </DashboardCard>
                  </Grid> : <Grid item xs={12} lg={6}>
                    <DashboardCard title="Most collected" contentPadding="0.2em" sxContent={{
              backgroundColor: '#f9f9f9'
            }} action={<SharePeople ratioLabel="Most collected" selectedProtocol={selectedProtocol} users={selectedUser.length ? selectedUser : tableData.collects.users} />}>
                      <MostCollectedTableDesktop users={tableData.collects.users} hasMoreUsers={tableData.collects.pagination.page < tableData.collects.pagination.totalPages} handlePageChange={() => handlePageChange(PeopleFilter.COLLECTS)} protocol={selectedProtocol} ratio={PeopleFilter.COLLECTS} adjusted={adjusted} compareUser={handleCompareUsers} isDetailView={false} />
                    </DashboardCard>
                  </Grid>)}
            </Grid>}
        </>
      </DashboardCard>
    </PageContainer>;
}