// ** React Imports
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

// ** Hooks
import { useAccount, useConfig, useDisconnect } from 'wagmi';
import { useLens } from './lens';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useHandleNetwork, isSupportedChainId } from './network';

// ** Utils
import { UserSessionStatus, SignInStatus } from '../types/custom';
import { ChainId } from '../types/network';
import { getSignInStatus, removeSignInStatus, setSignInStatus, wipeCredentials } from '../utils/helpers';
import { CredentialType } from '../types/auth/auth';
import { useModalsActions } from './modals';
import { UserSession } from '@/types/apps/users';
import { MonitorError } from '@/utils/error';
import { EIP1193Provider, useLogout, usePrivy, useWallets } from '@privy-io/react-auth';
interface SessionValue {
  user: UserSession;
  userProfiles: any[];
  notLoggedIn: boolean;
  connectedNotLogged: boolean;
  loggedIn: boolean;
  isLoadingSignIn: boolean;
  privyProvider: EIP1193Provider | undefined;
  logOut: () => Promise<any>;
  updateUserProfiles: (profiles: any[]) => void;
  handleSignInStatus: (status: SignInStatus) => void;
}
const SessionContext = createContext<SessionValue>({} as SessionValue);
type SessionProviderProps = {
  children: ReactNode;
};

/**
 * Session context provider
 */
export function SessionProvider({
  children
}: SessionProviderProps) {
  const {
    chainId,
    unsupportedNetwork
  } = useHandleNetwork();
  const {
    open: openModal
  } = useModalsActions();
  const {
    address: wagmiAddress,
    isConnected,
    isConnecting,
    isReconnecting
  } = useAccount();
  const {
    disconnect
  } = useDisconnect();
  const {
    chains
  } = useConfig();
  const {
    user,
    profileId,
    signOut: lensSignOut,
    isCheckingLocalKeys: isLensLoading,
    isCheckingLensData,
    isDisconnecting: isLensDisconnecting,
    handleLensDisconnecting,
    auth: lensSignature,
    isManagerEnabled,
    checkIsJWTstoraged,
    authenticateByRefreshToken,
    handleJWT
  } = useLens();
  const {
    authenticated,
    ready,
    user: privyUser
  } = usePrivy();
  const {
    wallets
  } = useWallets();
  const {
    logout: privyLogout
  } = useLogout();
  const address = privyUser?.wallet?.address || wagmiAddress;
  const [userProfiles, setUserProfiles] = useState<any[]>([]);
  const [isSetUpLoading, setIsSetUpLoading] = useState(false);
  const [privyProvider, setPrivyProvider] = useState<EIP1193Provider>();
  const [loggedAddress, setLoggedAddress] = useLocalStorage('address', null);
  const updateUserProfiles = (profiles: any[]) => {
    setUserProfiles(profiles);
  };

  /**
   * Whether localStorage keys are loading.
   */
  const areKeysLoading = isLensLoading;
  const isAppDisconnecting = isLensDisconnecting;
  const userStatus: UserSessionStatus = useMemo(() => {
    if (isConnected && ready && authenticated && profileId) {
      return UserSessionStatus.Logged;
    } else if (isConnected && ready && authenticated && !profileId) {
      return UserSessionStatus.ConnectedNotLogged;
    } else if (isConnecting || isReconnecting || areKeysLoading || isSetUpLoading || !ready) {
      return UserSessionStatus.Loading;
    } else {
      return UserSessionStatus.NotLogged;
    }
  }, [isConnected, isConnecting, isReconnecting, areKeysLoading, profileId, ready, authenticated]);
  const notLoggedIn = useMemo(() => userStatus === UserSessionStatus.NotLogged, [userStatus]);
  const connectedNotLogged = useMemo(() => userStatus === UserSessionStatus.ConnectedNotLogged, [userStatus]);
  const loggedIn = useMemo(() => userStatus === UserSessionStatus.Logged, [userStatus]);
  const isLoadingSignIn = useMemo(() => userStatus === UserSessionStatus.Loading, [userStatus]);

  /**
   * Sign out when the user changes.
   */
  useEffect(() => {
    cleanSessionAddress();
  }, [address]);
  useEffect(() => {
    if (isConnecting || isReconnecting) return;
    if (isConnected && ready && authenticated) {
      fetchProvider();
    }
  }, [isConnected, chainId, isConnecting, isReconnecting, ready, authenticated]);

  /**
   * Sign in with lens when the user connects the wallet.
   */
  useEffect(() => {
    const signInStatus = getSignInStatus();
    const validSignInStatus = signInStatus !== SignInStatus.completed && signInStatus !== SignInStatus.rejected;
    if (validSignInStatus && !areKeysLoading && !unsupportedNetwork && !isAppDisconnecting && !isCheckingLensData && ready && authenticated && address && !profileId) {
      logInWithLens();
    }
  }, [chainId, address, profileId, ready, authenticated]);
  useEffect(() => {
    if (isCheckingLensData) return;
    checkSignInStatus();
  }, [lensSignature, isManagerEnabled]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const checkSignInStatus = () => {
    if (isManagerEnabled === null) return;
    const signInStatus = getSignInStatus();
    if (signInStatus === SignInStatus.rejected) return;
    if (!lensSignature && !checkIsJWTstoraged() || lensSignature && !isManagerEnabled) {
      setSignInStatus(SignInStatus.pending);
    }
  };
  async function logInWithLens() {
    try {
      if (!address) throw new Error('No address found.');
      if (await checkIsJWTstoraged()) {
        const profile = await authenticateByRefreshToken();
        if (profile) {
          setSignInStatus(SignInStatus.completed);
          setLoggedAddress(address);
          return;
        }
      }
      if (lensSignature) {
        await handleJWT(address, lensSignature.message, lensSignature.accessToken, address);
        if (isManagerEnabled) {
          setSignInStatus(SignInStatus.completed);
        }
        setLoggedAddress(address);
        return;
      }
      openModal('signInProcess');
    } catch (error) {
      console.log('Error at login process: ', error);
      setSignInStatus(SignInStatus.pending);
      throw new MonitorError(error, {
        tags: {
          context: 'session',
          section: 'Login with lens'
        }
      });
    }
  }
  const fetchProvider = async () => {
    setIsSetUpLoading(true);
    try {
      let connectedAddress = address?.toLowerCase();
      if (!connectedAddress) return;
      const chain = chains.find(chain => chain.id === chainId);
      if (!chain) return;
      const isSupported = isSupportedChainId(chain.id as ChainId);
      if (!isSupported) return;
      const wallet = wallets.find(w => w.address === address);
      if (wallet) {
        const provider = await wallet?.getEthereumProvider();
        setPrivyProvider(provider);
        setLoggedAddress(wallet.address);
      } else {
        setPrivyProvider(undefined);
        setLoggedAddress(connectedAddress);
      }
    } catch (error) {
      console.log('Error setting up provider: ', error);
    } finally {
      setIsSetUpLoading(false);
    }
  };
  const handleSignInStatus = (status: SignInStatus) => {
    setSignInStatus(status);
  };

  /**
   * Clean the session
   */
  const cleanSession = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('accessToken');
    removeSignInStatus();
    setLoggedAddress(null);
  };

  /**
   * Clean the session when the adress changes.
   */
  const cleanSessionAddress = async () => {
    if (loggedAddress && address && loggedAddress.toLowerCase() !== address.toLowerCase()) {
      await logOut();
    }
  };
  async function logOut() {
    handleLensDisconnecting(true);
    try {
      // Logout from lens
      await lensSignOut();
    } catch (error) {
      console.log('Error signing out from lens: ', error);
    }
    wipeCredentials(address as `0x${string}`, CredentialType.all);
    await privyLogout();

    // Disconnect wallet from wagmi
    disconnect();

    // Remove JWT token
    cleanSession();
    handleLensDisconnecting(false);
  }
  const value: SessionValue = {
    user,
    userProfiles,
    updateUserProfiles,
    notLoggedIn,
    connectedNotLogged,
    loggedIn,
    isLoadingSignIn,
    handleSignInStatus,
    privyProvider,
    logOut
  };
  return <SessionContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="SessionProvider" data-sentry-source-file="session.tsx">{children}</SessionContext.Provider>;
}
export function useSession(): NonNullable<SessionValue> {
  return useContext(SessionContext);
}