import { Protocol } from '@/components/people/PeopleComponent';
import BlankCard from '@/components/shared/BlankCard';
import { useModalsActions } from '@/context/modals';
import { getAvatarUrl, getRankingEmoji } from '@/utils';
import { Avatar, AvatarGroup, Box, CircularProgress, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconShare } from '@tabler/icons-react';
import html2canvas from 'html2canvas';
import { useRef, useState } from 'react';
type ShareViewProps = {
  ratioLabel: string;
  users: any[];
  selectedProtocol: string;
};
const ShareDesktopView = ({
  ratioLabel,
  users,
  selectedProtocol
}: ShareViewProps) => {
  const theme = useTheme();
  const isLens = selectedProtocol === Protocol.LENS;
  return <BlankCard data-sentry-element="BlankCard" data-sentry-component="ShareDesktopView" data-sentry-source-file="SharePeople.tsx">
      <TableContainer data-sentry-element="TableContainer" data-sentry-source-file="SharePeople.tsx">
        <Table aria-label="share people view" data-sentry-element="Table" data-sentry-source-file="SharePeople.tsx">
          <TableHead data-sentry-element="TableHead" data-sentry-source-file="SharePeople.tsx">
            <TableRow data-sentry-element="TableRow" data-sentry-source-file="SharePeople.tsx">
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="SharePeople.tsx">
                <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">Rank</Typography>
              </TableCell>
              <TableCell data-sentry-element="TableCell" data-sentry-source-file="SharePeople.tsx">
                <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">Users</Typography>
              </TableCell>
              <TableCell align="center" style={{
              backgroundColor: '#f0f0f0'
            }} data-sentry-element="TableCell" data-sentry-source-file="SharePeople.tsx">
                <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">{ratioLabel}</Typography>
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">Last 7 days</Typography>
              </TableCell>
              <TableCell align="center" data-sentry-element="TableCell" data-sentry-source-file="SharePeople.tsx">
                <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">Total followers</Typography>
              </TableCell>
              <TableCell align="center" data-sentry-element="TableCell" data-sentry-source-file="SharePeople.tsx">
                <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="SharePeople.tsx">Followed by</Typography>
              </TableCell>
              {isLens && <TableCell align="center">
                  <Typography variant="h6">Lens score</Typography>
                </TableCell>}
            </TableRow>
          </TableHead>

          <TableBody data-sentry-element="TableBody" data-sentry-source-file="SharePeople.tsx">
            {users.map((item: any, index: number) => {
            const {
              user
            } = item;
            const ranking = 'rank' in item ? item.rank : index + 1;
            return <TableRow key={user.id} sx={{
              '&:last-child td, &:last-child th': {
                border: 0
              },
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                transition: 'background-color 0.3s'
              }
            }}>
                  <TableCell>
                    <Stack direction="row" spacing={0.2} alignItems="center">
                      <Typography variant="subtitle1" color="textSecondary">
                        {`#${ranking === -1 ? 'N/A' : ranking}`}
                      </Typography>
                      {ranking <= 3 && <Typography variant="subtitle1">
                          {getRankingEmoji(ranking)}
                        </Typography>}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Box sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    '& .avatar': {
                      filter: 'brightness(0.5)',
                      transition: '0.2s'
                    },
                    '& .name': {
                      color: theme.palette.primary.main,
                      transition: '0.2s'
                    },
                    '& .handle': {
                      color: theme.palette.grey[400],
                      transition: '0.2s'
                    }
                  }
                }}>
                      <Stack key={user.id} direction="row" alignItems="center" spacing={1} sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      '& .avatar': {
                        filter: 'brightness(0.5)',
                        transition: '0.2s'
                      },
                      '& .name': {
                        color: theme.palette.primary.main,
                        transition: '0.2s'
                      },
                      '& .handle': {
                        color: theme.palette.grey[400],
                        transition: '0.2s'
                      }
                    }
                  }}>
                        <Avatar className="avatar" src={getAvatarUrl(user)} sx={{
                      width: 42,
                      height: 42
                    }} />
                        <Box>
                          <Typography variant="h6" className="name">
                            {user.metadata?.displayName}
                          </Typography>
                          <Typography variant="caption" className="handle">
                            {user.handle && 'suggestedFormatted' in user.handle ? user.handle?.suggestedFormatted.localName : user.handle?.localName}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </TableCell>
                  <TableCell scope="row" align="center" style={{
                backgroundColor: '#f0f0f0'
              }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {item.ratioCount === -1 ? 'N/A' : String(item.ratioCount).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      {user.stats?.followers.toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="center">
                    <Box display="flex" justifyContent="center">
                      <AvatarGroup sx={{
                    justifyContent: 'start'
                  }}>
                        {item.topFollowers.length ? item.topFollowers.map((follower: any, i: number) => <Tooltip title={follower.handle?.localName} key={i}>
                              <Avatar src={getAvatarUrl(follower)} alt={follower.handle?.localName} sx={{
                        width: 35,
                        height: 35
                      }} />
                            </Tooltip>) : <Typography variant="caption">N/A</Typography>}
                      </AvatarGroup>
                    </Box>
                  </TableCell>
                  {isLens && <TableCell scope="row" align="center">
                      <Typography variant="subtitle1" color="textSecondary">
                        {user.stats?.lensClassifierScore?.toLocaleString()}
                      </Typography>
                    </TableCell>}
                </TableRow>;
          })}
          </TableBody>
        </Table>
      </TableContainer>
    </BlankCard>;
};
export const SharePeople = ({
  ratioLabel,
  users,
  selectedProtocol
}: ShareViewProps) => {
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const viewRef = useRef<HTMLDivElement>(null);
  const {
    open: openModal
  } = useModalsActions();
  const isLens = selectedProtocol === Protocol.LENS;
  const handleShare = async () => {
    try {
      if (!viewRef.current) return;
      setIsSharing(true);
      const originalStyles = viewRef.current.style.cssText;
      const captureWidth = isLens ? 1200 : 1000;
      viewRef.current.style.cssText = `
        position: fixed;
        left: '-9999px';
        top: '-9999px';
        width: ${captureWidth}px;
        visibility: visible;
        pointer-events: none;
        z-index: -9999;
      `;
      const canvas = await html2canvas(viewRef.current, {
        scale: 2,
        logging: true,
        useCORS: true,
        width: captureWidth,
        height: viewRef.current.offsetHeight,
        backgroundColor: '#ffffff'
      });
      viewRef.current.style.cssText = originalStyles;
      const image = canvas.toDataURL('image/png');
      openModal('shareStats', {
        type: 'people',
        payload: {
          screenShoot: image
        }
      });
    } catch (error) {
      console.error('Error capturing screenshot:', error);
    } finally {
      setIsSharing(false);
    }
  };
  return <>
      <IconButton aria-label="share" color="inherit" aria-controls="search-menu" aria-haspopup="true" size="large" onClick={handleShare} data-sentry-element="IconButton" data-sentry-source-file="SharePeople.tsx">
        {isSharing ? <CircularProgress size={20} /> : <IconShare size="20" />}
      </IconButton>

      <Box ref={viewRef} sx={{
      visibility: 'hidden',
      position: 'fixed',
      left: '-9999px',
      top: '-9999px',
      zIndex: -1,
      width: isLens ? '1200px' : '1000px',
      '& .MuiTableContainer-root': {
        width: '100%'
      },
      '& .MuiTable-root': {
        width: '100%'
      },
      '& .MuiTableCell-root': {
        whiteSpace: 'nowrap',
        padding: '16px'
      },
      '& .MuiTableCell-head': {
        backgroundColor: '#fff'
      }
    }} data-sentry-element="Box" data-sentry-source-file="SharePeople.tsx">
        <ShareDesktopView ratioLabel={ratioLabel} users={users} selectedProtocol={selectedProtocol} data-sentry-element="ShareDesktopView" data-sentry-source-file="SharePeople.tsx" />
      </Box>
    </>;
};